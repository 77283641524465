.show-menu-border {
  background-color: transparent !important;
  height: 96px !important;

  cursor: pointer;

  display: flex;
  overflow-x: scroll;

  padding-bottom: 20px;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    padding: 0 20px;

    &.active {
      color: #ed732e;
      background-color: #fff !important;
      box-shadow: inset 0px -2px 0px 0px #FF5E00;
    }

    &:hover {
      background-color: #fff !important;
      color: #ed732e;
      box-shadow: inset 0px -2px 0px 0px #FF5E00;
    }
  }
}

@media screen and (max-width: 768px) {
  .show-menu-border {
    .item {
      width: 120px;
    }
  }
}