$w: 1240px;

@function x($cw) {
  @return calc($cw / $w * 100vw);
}

@media only screen and (max-width: 768px) {
  .navbar {
    padding: 10px 0;
  }
}

@media only screen and (min-width: 768px) {
  .navbar {
    padding: 10px x(120px);
  }
}
