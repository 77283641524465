.case-header {
  padding: 0 10px;
  background-color: #f4f4f4;
  .case-title {
    padding-top: 60px;
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
    color: #181818;
  }
  .case-subtitle {
    padding-top: 10px;
    padding-bottom: 30px;
    font-size: 14px;
    line-height: 16px;
    color: #666666;
  }
}

.case-body {
  padding: 0 20px 80px 20px;
  max-width: 1200px;
  margin: 0 auto;
  .case-section {
    .case-section-title {
      padding-top: 40px;
      padding-bottom: 20px;
      font-size: 24px;
      line-height: 28px;
      color: #181818;
      border-bottom: #ededed solid 1px;
      font-weight: 600;
    }
    .case-section-list {
      margin-top: 20px;
      margin-bottom: 10px;
      margin-left: 20px;
      list-style-type: disc;
      li {
        list-style: disc;
        font-size: 14px;
        color: #3d3d3d;
        line-height: 28px;
      }
    }
  }
}
