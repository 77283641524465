$w: 1240px;
$h: 380px;
$hwRatio: calc($h / $w);

@function x($cw) {
  @return calc($cw / $w * 100vw);
}

.banner {
  background-image: url("./bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .banner {
    height: 188px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      color: white;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .banner {
    width: max(1200px, 100vw);
    height: 380px;

    position: relative;
    .banner-box {
      text-align: left;
      padding-top: 128px;
      padding-left: x(60px);
      .btn {
        margin-top: 48px;
      }
    }
  }
}
