.padding-40 {
  padding: 40px;
}

.partner-title {
  margin-top: 10px;
  font-size: 20px;
  line-height: 28px;
  color: #181818;
  font-weight: 600;
}

.partner-desc {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
}

.customer-img {
  width: 16.666%;
  padding: 10px;
  img {
    border: #f4f4f4 2px solid;
    width: 100%;
  }
}

.mt-40 {
  margin-top: 40px;
}

.mt-10 {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .padding-40 {
    padding: 20px;
  }
  .customer-img {
    width: 33.333%;
    padding: 5px;
  }
}
