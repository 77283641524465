$w: 1240px;
$h: 380px;
$hwRatio: calc($h / $w);

@function x($cw) {
  @return calc($cw / $w * 100vw);
}

.page {
  background: #fff;

  .banner1 {
    // width: 100%;
    height: 576px;
    background: url(./images/banner2.jpg) no-repeat;
    position: relative;
    background-size: 100% 576px;

    .banner-box {
      background: url(./images/banner.png) no-repeat;
      height: 100%;
      background-size: 100% 100%;

    }

    .title {
      // position: absolute;
      text-align: left;
      // left: 90px;
      // top: 113px;

      h1 {
        font-weight: bold;
      }

      p {
        width: 300px;
        font-size: 14px;
      }
    }
  }

  .index-show-menu {
    background-color: transparent !important;
    height: 96px !important;
    display: flex;
    justify-content: center;

    >.item {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      // min-width: x(191.25px);
      padding: 0 x(20px);
      flex: 1;

      &.active {
        color: #ed732e;
        background-color: #f4f4f4;

        span {
          color: #ed732e;
        }
      }

      &.active1 {
        color: #ed732e;
        box-shadow: inset 0px -2px 0px 0px #FF5E00;

        span {
          color: #ed732e;
        }
      }

      &:hover {
        background-color: #f4f4f4;
        color: #ed732e;

        span {
          color: #ed732e;
        }
      }
    }
  }

  .show-menu-bottom {
    margin-top: -96px;
    position: relative;
    z-index: 999;
    opacity: 0.8;
  }

  .show-menu-border {
    padding-bottom: 20px;
    background: #fff;
    font-size: 16px;
    font-weight: 600;

    .item {
      &.active {
        color: #ed732e;
        background-color: #fff !important;
        box-shadow: inset 0px -2px 0px 0px #FF5E00;
      }

      &:hover {
        background-color: #fff !important;
        color: #ed732e;
        box-shadow: inset 0px -2px 0px 0px #FF5E00;
      }
    }
  }

  .listCss {

    p {
      margin: 0 !important;
      padding: 0 !important;
      font-size: 14px;
      text-align: center;

      span {
        font-size: 18px;
        font-weight: 500;
      }


    }

  }

  .solution {
    padding: 20px 0 10px 0;
    text-align: center;
    background: #fff;

    h3 {
      margin-top: 62px;
      font-size: 24px;
      font-weight: 500;
      color: #181818;
    }
  }


  .solve-box {
    .solve {
      display: flex;
      
      .solve-left {
        width: 50%;
        height: 381px;

        &.show-pic-1 {
          background: url(./images/index2.jpg) no-repeat;
          background-size: cover;
        }

        &.show-pic-2 {
          background: url(./images/index3.jpg) no-repeat;
          background-size: cover;
        }

        &.show-pic-3 {
          background: url(./images/index4.jpg) no-repeat;
          background-size: cover;
        }

        &.show-pic-4 {
          background: url(./images/index5.jpg) no-repeat;
          background-size: cover;
        }

        &.show-pic-5 {
          background: url(./images/index6.jpg) no-repeat;
          background-size: cover;
        }

        &.show-pic-6 {
          background: url(./images/index7.jpg) no-repeat;
          background-size: cover;
        }
      }

      .solve-right {
        cursor: pointer;
        flex: 1;
        display: flex;
        align-items: center;
        background: linear-gradient(135deg, #FF6A00 0%, #FF9E2D 100%);
        color: #fff;
        padding-left: 50px;
        width: 550px;

        h2 {
          margin-right: 9px;

          img {
            margin-top: -15px;
          }
        }

        p {
          text-align: left;
          width: 521px;
          font-size: 14px;
          line-height: 23.8px;
        }
      }
    }
  }

  .technology {
    cursor: pointer;
    background: #fff;
    padding-bottom: 60px;

    .technology-item {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4;
      text-align: left;

      .technology-title {
        margin: 45px 0 51px 0;
        width: 480px;

        h2 {
          font-size: 16px;
          font-weight: 600;
          color: #181818;
        }

        >div {
          margin-top: 10px;
          font-size: 14px;
          color: #666666;
        }

        p {
          margin-top: 27px;

          span {
            font-size: 12px;
            font-weight: 500;
            color: #FF6A00;
          }
        }
      }

      &>div:first-child {
        border-right: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
      }

      &>div:nth-child(2) {
        border-bottom: 1px solid #f4f4f4;
      }

      &>div:nth-child(3) {
        border-right: 1px solid #f4f4f4;

      }

      &>div {
        width: 50%;
      }

      .item {

        &:nth-child(1),
        &:nth-child(3) {
          justify-content: end;
          padding-right: 120px;
        }

        &:nth-child(2),
        &:nth-child(4) {
          padding-left: 120px;
        }

        &:hover {
          background: url(./images/index12.png) no-repeat;
          background-size: cover;
        }
      }

    }

  }

  .case1 {
    background-color: #f4f4f4;

    .swiper {
      width: 100%;
      padding-bottom: 30px;
      cursor: pointer;

      .carousel-indicators {
        bottom: -50px;

        button {
          background-color: #FF6A00 !important;
        }
      }

      .carousel-inner {
        .swiper-list {
          display: flex;
          justify-content: center;

          .carousel-item {
            background: #fff;

            .swiper-img {
              img {
                width: 600px;
                height: 469px;
              }
            }
          }

          .swiper-title {
            width: 600px;
            height: 469px;
            padding: 60px 60px 0 60px;
            text-align: left;
            background: #fff;


            h2 {
              font-size: 16px;
              font-weight: 600;
              color: #181818;
              margin-bottom: 20px;
            }

            >p {
              font-size: 14px;
              color: #666666;

              span {
                display: block;
                width: 18px;
                border-bottom: 3px solid #FF6A00;
              }
            }


            >p:nth-child(4) {
              font-size: 12px;

              color: #FF791A;
              font-weight: 600;
            }
          }
        }
      }

      .carousel-control-next {
        width: 32px;
        right: calc(50% - 600px - 64px);
      }

      .carousel-control-prev {
        width: 32px;
        left: calc(50% - 600px - 64px);
      }

    }
  }

  .btnMe {
    margin-top: 30px;
    padding-bottom: 45px;
    display: flex;
    justify-content: center;

    .btn {
      background: #FF6A00;
      border-radius: 5px 5px 5px 5px;
      width: 280px;
      height: 46px;
      border: none;
      font-size: 12px;
      font-weight: 500;
      color: #FFFFFF;
    }
  }

  .cooperate {
    margin-bottom: 60px;
    padding: 20px 0;
    background: #fff;

    .cooperate-child {
      display: flex;
      justify-content: center;

      .cooperate-content {


        >div {
          width: 250px;
          height: 380px;
          padding: 30px;
          background: url(./images/index47.png) no-repeat;
          background-size: 100%;
          border: 1px solid #f4f4f4;
          margin: 0 8px;
          text-align: left;

          &:hover {
            box-shadow: 1px 0px 8px 1px rgba(189, 185, 185, 0.5);
          }

          h2 {
            img {
              width: 205px;
            }
          }

          p {
            font-size: 12px;
            color: #666666;

            span {
              background: #f4f4f4;
              padding: 5px;
            }
          }

          h3 {
            margin-top: 50px;
            font-size: 14px;
            font-weight: 600;
            color: #181818;
          }
        }
      }
    }
  }

  // .image-box {
  //   width: 100vw;

  //   .image {
  //     width: 100vw;
  //   }
  // }

  .banner2 {
    width: 100vw;
    height: 480px;
    background: url(./images/index25.jpg) no-repeat;
    position: relative;
    background-size: 100% 480px;
    text-align: center;

    .title {
      padding: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 22px;
        font-weight: bold;
      }

      p {
        font-size: 14px;

      }
    }

    .btn {
      background: #FF6A00;
      font-weight: 500;
      color: #FFFFFF;
      font-size: 14px;
      border: #FF6A00;
    }
  }

  .row {
    margin-right: 0 !important;

  }

  .show-menu2 {
    display: flex;
    position: absolute;
    top: 350px;
    width: 100%;
    z-index: 999;
    flex-wrap: wrap;

    .menu2-list {
      width: 44%;
      padding: 15px 0;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 0px 0px 0px 0px;
      opacity: 0.9;
      margin: 3% 3%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 12px;

        span {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .item {

      &.menu2-active {
        color: #ed732e;
        background-color: #f4f4f4;

        span {
          color: #ed732e;
        }
      }

      &:hover {
        background-color: #f4f4f4;
        color: #ed732e;

        span {
          color: #ed732e;
        }
      }
    }
  }

  .image-box1 {
    width: 100%;

    .cloudata-title {
      flex: 1;
      margin-top: 40px;
      margin-left: 30px;
      margin-right: 30px;

      span {
        cursor: pointer;
        font-size: 24px;
        font-weight: bold;

        &:hover {
          // background-color: #f4f4f4;
          color: #ed732e;
        }
      }

      h3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 60px;

      }

      p {
        font-size: 14px;
        color: #666666;
      }


    }

    ul {
      display: flex;
      margin-top: 6px;
      margin: 0 auto;
      justify-content: center;
      margin-top: 30px;


      li {
        font-size: 12px;
        font-weight: 500;
        margin-right: 15px;

        img {
          width: 32px;
          height: 30px;
        }
      }
    }

    .cloudata-img {
      width: 100%;
      margin-top: 20px;
      margin: 0 auto;
      margin-bottom: 60px;

      >img {
        width: 94%;
      }
    }
  }

  .solution2 {
    background: #F4F4F4;
    padding-top: 50px;
    padding-bottom: 65px;

    h2 {
      text-align: center;
    }

    .solution2-box {
      margin: 15px 0 23px 0;
      max-width: 750px;
      white-space: nowrap;
      overflow: hidden;

      .solution2-child {
        height: 100%;
        // width: 1800px;
        white-space: nowrap;
        overflow-x: auto;

        .item2 {
          display: inline-block;
          height: 100%;
          padding: 10px 10px;
          margin: 0 10px;

          &.active1 {
            color: #ed732e;
            // background-color: #f4f4f4;
            box-shadow: inset 0px -2px 0px 0px #FF5E00;

            span {
              color: #ed732e;
            }
          }

          &:hover {
            background-color: #f4f4f4;
            color: #ed732e;
            box-shadow: inset 0px -2px 0px 0px #FF5E00;

            span {
              color: #ed732e;
            }
          }
        }
      }
    }

    .image-box2 {
      .image {
        width: 94% !important;
      }
    }

  }

  .solve-box2 {
    .solve {
      width: 94%;
      margin: 0 auto;

      .solve-left {
        img {
          width: 100%;
        }
      }

      .solve-right {
        background: linear-gradient(135deg, #FF6A00 0%, #FF9E2D 100%);
        color: #fff;
        padding-top: 33px;
        padding-bottom: 33px;

        h2 {
          margin-right: 9px;

          img {
            margin-top: -15px;
          }
        }

        p {
          text-align: left;
          padding: 0 16px;
          font-size: 12px;
        }
      }
    }
  }

  .technology2 {
    padding-bottom: 60px;
    background: #fff;
    cursor: pointer;
    width: 94%;
    margin: 0 auto;

    h2 {
      padding: 35px 0 20px 0;
    }

    .technology-item {
      text-align: left;

      .technology-title {
        padding: 24px 16px;
        border: 1px solid #F4F4F4;
        margin-bottom: 15px;

        h2 {
          font-size: 16px;
          font-weight: 600;
          color: #181818;
        }

        >div {
          margin-top: 10px;
          font-size: 12px;
          color: #666666;
        }

        p {
          margin-top: 27px;

          span {
            font-size: 12px;
            font-weight: 500;
            color: #FF6A00;
          }
        }
      }

      &.active2 {
        background: #ff0;
        background-size: 100%;

      }

      .items {
        &:hover {
          background: url(./images/index50.jpg) no-repeat;
          background-size: cover;
        }
      }

    }
  }

  .case2 {
    background: #252525;

    .solution {
      background: #252525;
      color: #fff;
    }

    .swiper {
      width: 94%;
      padding-bottom: 30px;
      margin: 0 auto;
      cursor: pointer;

      .carousel-indicators {
        bottom: -50px;

        button {
          background-color: #FF6A00 !important;
        }
      }

      .carousel-inner {
        .swiper-list {
          img {
            width: 100%;
          }

          .swiper-title {
            padding: 40px;
            text-align: left;
            background: #fff;


            h2 {
              font-size: 16px;
              font-weight: 600;
              color: #181818;
              margin-bottom: 20px;
            }

            >p {
              font-size: 12px;
              color: #666666;

              span {
                display: block;
                width: 18px;
                border-bottom: 3px solid #FF6A00;
              }
            }


            >p:nth-child(4) {
              font-size: 12px;

              color: #FF791A;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .cooperate2 {
    background: #fff;
    width: 94%;
    margin: 0 auto;



    .cooperate-content {
      &:hover {
        box-shadow: 1px 0px 8px 1px rgba(189, 185, 185, 0.5);


      }

      >div {
        background: url(./images/index48.jpg) no-repeat;
        background-size: 100%;
        text-align: left;
        padding: 0 19px 10px 19px;
        margin-bottom: 20px;
        border-bottom: 1px solid #f4f4f4;

        h2 {
          img {
            width: 50%;
          }
        }

        p {
          font-size: 12px;
          color: #666666;

          span {
            background: #f4f4f4;
            padding: 5px;
          }
        }

        h3 {
          margin-top: 50px;
          font-size: 14px;
          font-weight: 600;
          color: #181818;
        }
      }

    }
  }

  .cloudata {
    display: flex;
    text-align: left;

    .cloudata-title {
      flex: 1;
      margin-top: 60px;
      margin-left: 82px;
      margin-right: 60px;

      span {
        cursor: pointer;
        font-size: 24px;
        font-weight: bold;

        &:hover {
          // background-color: #f4f4f4;
          color: #ed732e;
        }
      }

      h3 {
        font-size: 18px;
        font-weight: 400;
        line-height: 60px;

      }

      p {
        font-size: 14px;

        color: #666666;
      }

      ul {
        display: flex;
        margin-top: 6px;

        li {
          font-size: 12px;
          font-weight: 500;
          margin-right: 15px;

          img {
            width: 32px;
            height: 30px;
          }
        }
      }
    }

    .cloudata-img {
      width: 416px;
      height: 210px;
      margin-top: 62px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// @media only screen and (max-width: 768px) {
//   .banner1 {
//     height: 188px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;

//     .title {
//       color: white;
//       margin-bottom: 20px;
//     }
//   }

//   .show-menu {
//     justify-content: unset;
//   }
// }

@media only screen and (min-width: 768px) {
  .banner1 {
    width: max(1200px, 100vw);
    height: 576px !important;
    // background-size: 100% 576px;
    position: relative;

    .banner-box {
      text-align: left;
      padding-top: 128px;
      padding-left: x(60px);

      .btn {
        margin-top: 48px;
      }
    }
  }

  .show-menu11 {
    justify-content: unset;
  }
}