$w: 1440px;
$h: 380px;
$hwRatio: calc($h / $w);

@function x($cw) {
  @return calc($cw / $w * 100vw);
}

@function y($ch) {
  @return calc($ch * 100vw / $w);
}

.footer {
  width: 100%;
  background: #252525;
  opacity: 1;

  .footer-case {
    background-image: url(../../assets/banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: x(60px) 0 x(60px) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 40px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 47px;
      margin-bottom: 30px;
    }

    p {
      display: flex;
      justify-content: center;
      margin: 0 !important;
    }

    button {
      width: 280px;
      height: 48px;
      background: #FF6A00;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 19px;
      border-color: #FF6A00;
    }

    .btn2 {
      width: 104px;
      height: 36px;
      font-size: 12px;
    }

    .title2 {
      line-height: 25px;
      font-size: 24px;
      margin-bottom: 16px;
    }
  }


  .footer-list {
    display: flex;
    justify-content: space-around;
    padding: 60px 0;
    border-bottom: 1px solid #999;

    ol {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 14px;

      .fist-li {
        &:hover {
          color: #fff !important;
          cursor: default;
        }
      }

      li {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-right: 7%;
        text-align: left;
        color: #ddd;

        &:hover {
          color: #FF6A00;
          cursor: pointer;
        }

        span {
          color: #ddd;
        }

        .span-li {
          display: block;
          margin-bottom: 4px;
        }

        b {
          color: #fff;
          font-size: 14px;
        }
      }

      .input-li {
        width: 232px;
        height: 36px;
        background: rgba(255, 255, 255, 0.02);
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        display: flex;
        align-items: center;
        padding-left: 20px;
        margin-bottom: 10px;
      }

      .input-li-last {
        display: flex;
        width: 232px;
        height: 114px;
        background: rgba(255, 255, 255, 0.02);
        border-radius: 20px 20px 20px 20px;
        padding-left: 20px;
        padding-right: 10px;
        align-items: center;
        justify-content: space-between;

        input {
          width: 80%;
          border: 1px solid rgba(255, 255, 255, 0.1);
          background: rgba(255, 255, 255, 0.02);
        }

        img {
          width: 75px;
          height: 75px;
          margin-right: 10px;
        }
      }

      .fist-li {
        color: #fff;
        font-size: 14px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 16px;
        margin: 8px 0 8px 0;
      }
    }
  }

  .footer-trademark {
    color: #fff;
    font-size: 12px;
    margin-top: 20px;
    padding-bottom: 30px;
    text-align: center;
  }

  #accordion {}

  .card {
    // background-color: #3D3D3D;
    border: 1px solid #252525;
    color: #fff;

    .card-header {
      background-color: #252525;
      border-bottom: 1px solid #3D3D3D !important;
      text-align: left;

      .btn1 {
        padding: 0 !important;
        margin: 0 0 !important;
        color: #fff;
        padding: 0;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }

    .card-header:first-child {
      border-radius: 0 !important;
    }


    .card-body {
      box-shadow: 5px 0 5px 0 rgba(13, 13, 13, 0.9);
      background-color: #3D3D3D;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
      text-align: left;
    }
  }

  .message {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .phone {
      padding: 10px 10px;
      width: 94%;
      background: #FF6A00;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 16px;
      font-weight: 500;
      margin-top: 40px;
    }

    .email {
      width: 94%;
      padding: 10px 10px;
      background: #3D3D3D;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 16px;
      font-weight: 500;
      margin-top: 40px;
    }

    .fillIN {
      width: 94%;
      margin-top: 20px;
      text-align: center;

      img {
        width: 160px;
        height: 160px;
      }

      .color {
        color: #999999;
      }

    }
  }

}

@media only screen and (max-width: 768px) {
  .footer {
    .footer-case {
      background-image: url(../../assets/index51.png);
      height: 146px;
      padding: 0;
    }
  }
}