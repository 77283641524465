$w: 1440px;

@function x($cw) {
  @return calc($cw / $w * 100vw);
}

.page {
  overflow: auto;
  .show-menu {
    height: 76px;
    background-color: white;
    display: flex;
    justify-content: center;
    overflow-x: auto;

    .item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 191.25px;
      padding: 0 20px;

      a {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.active {
        color: #ed732e;
        background-color: #f4f4f4;
        font-weight: 800;
      }

      &:hover {
        color: rgba(#ed732e, .9);
        background-color: #f4f4f4;
      }
    }
  }

  .industry {
    display: inline-block;
    width: 25%;
    padding: 10px;
    .block {
      width: 100%;
      height: 220px;
      background-color: white;
      img {
        width: 100%;
      }
      .title {
        padding: 20px 0;
        line-height: 23px;
        font-weight: 600;
        font-size: 20px;
      }
    }
  }

  .box3 {
    position: relative;

    .btns {
      width: 285px;
      height: 228px;
      position: absolute;
      right: 120px;
      bottom: 65px;
      background: #fff;
      text-align: center;

      .btn {
        width: 130px;
        position: absolute;
        left: 29%;
        top: 40%;
        z-index: 9;
        font-size: 16px;
        transform: scale(0.7);
      }
    }

    .btns2 {
      width: 640px;
      height: 470px;
      position: absolute;
      right: 60px;
      bottom: 156px;
      background: #fff;
      text-align: center;

      .btn {
        width: 280px;
        position: absolute;
        left: 29%;
        top: 40%;
        z-index: 9;
        font-size: 16px;
        transform: scale(0.7);
      }
    }
  }
}

.padding-60 {
  padding: 60px 120px;
}

.full-box {
  min-width: 1200px;
}

.slogan-title {
  display: inline-block;
}

.slogan-desc {
  display: inline-block;
}

.team {
  padding: 0 20px;
  display: block;
}
.team-grid {
  padding: 0 20px;
  display: none;
}

.contact-btn {
  display: block;
}
.contact-btn-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .padding-60 {
    padding: 15px 30px;
  }
  .full-box {
    min-width: auto;
  }
  .slogan-title {
    margin-top: 20px;
    display: block;
  }
  .slogan-desc {
    display: block;
  }
  .page {
    .industry {
      display: inline-block;
      width: 50%;
      padding: 10px;
      .block {
        width: 100%;
        height: 160px;
        background-color: white;
        img {
          width: 100%;
        }
        .title {
          padding: 20px 0;
          line-height: 23px;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
  }
  .team {
    display: none;
  }
  .team-grid {
    display: block;
  }

  .contact-btn {
    display: none;
  }
  .contact-btn-mobile {
    display: block;
  }
}
