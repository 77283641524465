$primary: #ed732e;

$offcanvas-vertical-height: 80vh;

.btn-primary {
  color: white;
}

.navbar-light .navbar-nav .nav-link {
  &:hover {
    color: rgba(#ed732e, .7);
  }
  &.active {
    color: rgba(#ed732e, .9);
  }
}

@import "bootstrap";
