.page {
  overflow: hidden;
  .show-menu {
    height: 76px;
    background-color: white;
    display: flex;
    justify-content: center;

    .item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      &.active {
        color: #ed732e;
        background-color: #f4f4f4;
        font-weight: 800;
      }
      &:hover {
        color: rgba(#ed732e, .9);
        background-color: #f4f4f4;
      }
    }
  }
  .solution-title-text {
    width: 868px;
  }
}

@media only screen and (max-width: 768px) {
  .page {
    .show-menu {
      overflow-x: scroll;
      justify-content: start;
      .item {
        width: 120px;
      }
    }
    .solution-title-text {
      width: 70vw;
    }
  }
}

@media only screen and (min-width: 768px) {
  .page {
    .show-menu {
      .item {
        min-width: 196px;
      }
    }
  }
}
