.case {
  width: 33.33%;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .case {
    width: 100%;
  }
}
